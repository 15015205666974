import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    //FORM HEADERS
    editCompanyProfileFormHeader
} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //FORM HEADERS
        editCompanyProfileFormHeader
    },
    mutations: {},
    actions: {},
    modules: {},
});