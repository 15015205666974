//----------------------------------------------
//FORM HEADERS

//Company Profile Form Header
const editCompanyProfileFormHeader = {
    form_header_title: 'Company Profile',
    form_header_to: '/',
    form_secondary_btn_show: true,
    form_secondary_button_icon: 'mdi-cog',
    form_secondary_button_name: 'Show Advanced Options'
}



//EXPORTED to the Vuex state block
export {
    //FORM HEADERS
    editCompanyProfileFormHeader
}