<template>
  <v-container>
    <v-row class="mb-8">
      <v-col lg="10" offset-lg="1">
        <v-card-title>
          <v-btn to="/" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h2>Company Profile</h2>
          <!--Show secondary action button-->
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            color="secondary"
            light
            class="ml-10"
            @click="ShowAdvancedFields()"
          >
            Show Advanced Options
            <v-icon right>mdi-cog</v-icon>
          </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="10" offset-lg="1">
        <!--Show advanced options Button-->

        <v-sheet elevation="4">
          <!--New Customer form -->
          <v-form
            @submit.prevent="updateCompanyProfile"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <!--BASIC INFO-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Basic Info</h4>
                </v-col>
              </v-row>
              <!--Registered Name-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_legal_name"
                    label="Company Legal Name"
                    Required
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <!--Trading As-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_trading_as"
                    label="Trading As"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!--Registration Number-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_reg_number"
                    label="Registration Number"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <!--VAT Number-->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_VAT_number"
                    label="VAT Number"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="company_org_type"
                    :items="[
                      'Company',
                      'Partnership',
                      'Sole Trader',
                      'Person',
                      'Trust',
                      'Charity',
                    ]"
                    label="Organisation Type"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!--Logo Upload-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Upload Logo</h4>
                </v-col>
              </v-row>
              <!--Image upload-->
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-btn
                    @click="imageUploadClick"
                    elevation="2"
                    color="#33cc99"
                    dark
                    left
                    >Choose Image
                    <v-icon right>mdi-upload</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <!--Upload preview-->
                <v-col cols="12" sm="12" md="4">
                  <v-card width="180">
                    <div>
                      <v-img
                        class="preview"
                        height="180"
                        width="180"
                        :src="this.company_logo"
                        lazy-src="@/assets/lazy-src.png"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#c5d0ed"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <input
                      type="file"
                      ref="input1"
                      style="display: none"
                      @change="previewImage"
                      accept="image/*"
                    />
                  </v-card>
                </v-col>
              </v-row>

              <!--Contact Details-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Contact Details</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_phone"
                    append-icon="mdi-phone"
                    type="tel"
                    label="Phone Number"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_email"
                    append-icon="mdi-email"
                    label="Email"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="company_website"
                    label="Website"
                    append-icon="mdi-link-variant"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Physical address-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Physical Address</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_physical_address_line_1"
                    label="Address Line 1"
                    append-icon="mdi-map-marker"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_physical_address_line_2"
                    label="Address Line 2"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="company_physical_address_city"
                    label="City"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model.number="company_physical_address_zip"
                    type="number"
                    label="ZIP Code"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="company_physical_address_country"
                    :items="countryList"
                    label="Country"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_physical_address_province"
                    label="Province / State"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Postal address-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Postal Address</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_postal_address_line_1"
                    label="Address Line 1"
                    append-icon="mdi-postage-stamp"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_postal_address_line_2"
                    label="Address Line 2"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="company_postal_address_city"
                    label="City"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model.number="company_postal_address_zip"
                    type="number"
                    label="ZIP Code"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="company_postal_address_country"
                    :items="countryList"
                    label="Country"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_postal_address_province"
                    label="Province / State"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Banking Details-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h4>Banking Details</h4>
                </v-col>
              </v-row>
              <!--Primary Bank Account-->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="primary_bank_account"
                    item-text="bank_account_name"
                    :return-object="true"
                    :items="bank_accounts"
                    label="Primary Bank Account"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_bank_name"
                    :value="getBankInfo()"
                    readonly
                    append-icon="mdi-bank"
                    label="Bank Name"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="company_bank_account_num"
                    readonly
                    label="Account Number"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :value="company_bank_branch_code"
                    readonly
                    label="Branch Code"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--Advanced options fields-->
              <div ref="advanced_fields"></div>

              <div v-if="advanced_fields" transition="slide-y-transition">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <h4>Advanced Options</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-switch
                      v-model="company_is_vattable"
                      label="Company is VAT registered"
                      color="#33cc99"
                    ></v-switch>
                  </v-col>
                  <!--VAT Start Date-->
                  <v-col cols="6" sm="6" md="6" v-if="company_is_vattable">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="true"
                      :return-value.sync="date"
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="VAT_start_date"
                          label="Date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          clearable
                          color="#3d2cdd"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="VAT_start_date"
                        no-title
                        scrollable
                        color="#3d2cdd"
                        show-adjacent-months
                        :allowed-dates="
                          (date) =>
                            date <= new Date().toISOString().substr(0, 10)
                        "
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="#ce2458" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="#33cc99"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!--Year of the first finacial period that will be recorded in the system-->
                  <v-col cols="6" sm="6" md="6">
                    <v-select
                      v-model="first_fiscal_year"
                      :items="fiscal_year_items"
                      label="First Fiscal Year"
                      color="#33cc99"
                      outlined
                      dense
                      hint="Year of the first finacial period that will be recorded in the system"
                      persistent-hint
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <!--Start of finacial year-->
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-autocomplete
                      :items="[
                        { name: 'January', value: 0 },
                        { name: 'February', value: 1 },
                        { name: 'March', value: 2 },
                        { name: 'April', value: 3 },
                        { name: 'May', value: 4 },
                        { name: 'June', value: 5 },
                        { name: 'July', value: 6 },
                        { name: 'August', value: 7 },
                        { name: 'September', value: 8 },
                        { name: 'October', value: 9 },
                        { name: 'November', value: 10 },
                        { name: 'December', value: 11 },
                      ]"
                      item-text="name"
                      item-value="value"
                      v-model="fiscal_year_start"
                      label="Start of Fiscal Year"
                      color="#33cc99"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="updateCompanyProfile"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Update
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import store from "../store/store";
import countries from "../../../composables/countries";
import { showSnackbar } from "../../../globalActions/index";
import { getBankAccounts } from "../../Banking/data/external_bank_accounts";

export default {
  name: "CompanyProfile",
  data() {
    return {
      //Initially set all database values to null
      menu: null,
      company_id: null,
      company_legal_name: null,
      company_trading_as: null,
      company_reg_number: null,
      company_VAT_number: null,
      company_org_type: null,
      company_phone: null,
      company_email: null,
      company_website: null,
      company_physical_address_line_1: null,
      company_physical_address_line_2: null,
      company_physical_address_city: null,
      company_physical_address_zip: null,
      company_physical_address_country: null,
      company_physical_address_province: null,
      company_postal_address_line_1: null,
      company_postal_address_line_2: null,
      company_postal_address_city: null,
      company_postal_address_zip: null,
      company_postal_address_country: null,
      company_postal_address_province: null,
      primary_bank_account: null,
      bank_accounts: [],
      company_bank_name: null,
      company_bank_id: null,
      company_bank_account_num: null,
      company_bank_branch_code: null,
      //List of countries imported for dropdown
      countryList: countries,
      //Form validation
      valid: false,
      //Advanced options
      advanced_fields: false,
      company_is_vattable: false,
      VAT_start_date: null,
      fiscal_year_start: null,
      first_fiscal_year: null,
      //Logo upload data
      company_logo: "",
      loading: false,
      fiscal_year_items: [],
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("company_profile")
      .where("company_id", "==", to.params.company_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.company_id = doc.data().company_id;
            vm.company_legal_name = doc.data().company_legal_name;
            vm.company_trading_as = doc.data().company_trading_as;
            vm.company_reg_number = doc.data().company_reg_number;
            vm.company_VAT_number = doc.data().company_VAT_number;
            vm.company_org_type = doc.data().company_org_type;
            vm.company_phone = doc.data().company_phone;
            vm.company_email = doc.data().company_email;
            vm.company_website = doc.data().company_website;
            vm.company_physical_address_line_1 =
              doc.data().company_physical_address_line_1;
            vm.company_physical_address_line_2 =
              doc.data().company_physical_address_line_2;
            vm.company_physical_address_city =
              doc.data().company_physical_address_city;
            vm.company_physical_address_zip =
              doc.data().company_physical_address_zip;
            vm.company_physical_address_country =
              doc.data().company_physical_address_country;
            vm.company_physical_address_province =
              doc.data().company_physical_address_province;
            vm.company_postal_address_line_1 =
              doc.data().company_postal_address_line_1;
            vm.company_postal_address_line_2 =
              doc.data().company_postal_address_line_2;
            vm.company_postal_address_city =
              doc.data().company_postal_address_city;
            vm.company_postal_address_zip =
              doc.data().company_postal_address_zip;
            vm.company_postal_address_country =
              doc.data().company_postal_address_country;
            vm.company_postal_address_province =
              doc.data().company_postal_address_province;
            vm.primary_bank_account = doc.data().primary_bank_account;
            vm.company_bank_name = doc.data().company_bank_name;
            vm.company_bank_id = doc.data().company_bank_id;
            vm.company_bank_account_num = doc.data().company_bank_account_num;
            vm.company_bank_branch_code = doc.data().company_bank_branch_code;
            vm.company_logo = doc.data().company_logo;
            vm.company_is_vattable = doc.data().company_is_vattable;
            vm.VAT_start_date = doc.data().VAT_start_date;
            vm.fiscal_year_start = doc.data().fiscal_year_start;
            vm.first_fiscal_year = doc.data().first_fiscal_year;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  computed: {
    //This is where data is retrieved from the Vuex state
    editCompanyProfileFormHeader() {
      return store.state.editCompanyProfileFormHeader;
    },
  },
  created() {
    this.getBankAccounts();
  },
  beforeUpdate() {
    this.populateFiscalYearDropdown();
  },
  methods: {
    populateFiscalYearDropdown() {
      for (let i = 2017; i < new Date().getFullYear(); i++) {
        this.fiscal_year_items.push(i);
      }
    },
    getBankAccounts,
    getBankInfo() {
      if (this.primary_bank_account != null) {
        (this.company_bank_name = this.primary_bank_account.bank_account_name),
          (this.company_bank_branch_code =
            this.primary_bank_account.bank_account_branch_code);
        this.company_bank_account_num =
          this.primary_bank_account.bank_account_number;
        this.company_bank_id = this.primary_bank_account.bank_account_id;
      }
    },
    //Maps database data to template relative to the customer selected in this route
    fetchData() {
      db.collection("company_profile")
        .where("company_id", "==", this.$route.params.company_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.company_id = doc.data().company_id;
            this.company_legal_name = doc.data().company_legal_name;
            this.company_trading_as = doc.data().company_trading_as;
            this.company_reg_number = doc.data().company_reg_number;
            this.company_VAT_number = doc.data().company_VAT_number;
            this.company_org_type = doc.data().company_org_type;
            this.company_phone = doc.data().company_phone;
            this.company_email = doc.data().company_email;
            (this.company_website = doc.data().company_website),
              (this.company_physical_address_line_1 =
                doc.data().company_physical_address_line_1);
            this.company_physical_address_line_2 =
              doc.data().company_physical_address_line_2;
            this.company_physical_address_city =
              doc.data().company_physical_address_city;
            this.company_physical_address_zip =
              doc.data().company_physical_address_zip;
            this.company_physical_address_country =
              doc.data().company_physical_address_country;
            this.company_physical_address_province =
              doc.data().company_physical_address_province;
            this.company_postal_address_line_1 =
              doc.data().company_postal_address_line_1;
            this.company_postal_address_line_2 =
              doc.data().company_postal_address_line_2;
            this.company_postal_address_city =
              doc.data().company_postal_address_city;
            this.company_postal_address_zip =
              doc.data().company_postal_address_zip;
            this.company_postal_address_country =
              doc.data().company_postal_address_country;
            this.company_postal_address_province =
              doc.data().company_postal_address_province;
            this.primary_bank_account = doc.data().primary_bank_account;
            this.company_bank_name = doc.data().company_bank_name;
            this.company_bank_id = doc.data().company_bank_id;
            this.company_bank_account_num = doc.data().company_bank_account_num;
            this.company_bank_branch_code = doc.data().company_bank_branch_code;
            this.company_is_vattable = doc.data().company_is_vattable;
            this.VAT_start_date = doc.data().VAT_start_date;
            this.fiscal_year_start = doc.data().fiscal_year_start;
            this.first_fiscal_year = doc.data().first_fiscal_year;
            this.company_logo = doc.data().company_logo;
          });
        });
    },
    //Updates form values in the database
    updateCompanyProfile() {
      //  Set primary bank account
      if (this.$refs.form.validate()) {
        this.loading = true;
        const batch = db.batch();
        //  Save field values to database
        const profileRef = db
          .collection("company_profile")
          .doc(this.$route.params.company_id);

        batch.update(profileRef, {
          company_id: this.company_id,
          company_legal_name: this.company_legal_name,
          company_trading_as: this.company_trading_as,
          company_reg_number: this.company_reg_number,
          company_VAT_number: this.company_VAT_number,
          company_org_type: this.company_org_type,
          company_phone: this.company_phone,
          company_email: this.company_email,
          company_website: this.company_website,
          company_physical_address_line_1: this.company_physical_address_line_1,
          company_physical_address_line_2: this.company_physical_address_line_2,
          company_physical_address_city: this.company_physical_address_city,
          company_physical_address_zip: this.company_physical_address_zip,
          company_physical_address_country:
            this.company_physical_address_country,
          company_physical_address_province:
            this.company_physical_address_province,
          company_postal_address_line_1: this.company_postal_address_line_1,
          company_postal_address_line_2: this.company_postal_address_line_2,
          company_postal_address_city: this.company_postal_address_city,
          company_postal_address_zip: this.company_postal_address_zip,
          company_postal_address_country: this.company_postal_address_country,
          company_postal_address_province: this.company_postal_address_province,
          primary_bank_account: this.primary_bank_account,
          company_bank_name: this.company_bank_name,
          company_bank_id: this.company_bank_id,
          company_bank_account_num: this.company_bank_account_num,
          company_bank_branch_code: this.company_bank_branch_code,
          company_is_vattable: this.company_is_vattable,
          VAT_start_date: this.VAT_start_date,
          fiscal_year_start: this.fiscal_year_start,
          first_fiscal_year: this.first_fiscal_year,
          company_logo: this.company_logo,
        });

        //  Set selected bank accountto "primary"
        const primaryBankRef = db
          .collection("bank_accounts")
          .doc(this.company_bank_id);
        batch.update(primaryBankRef, {
          primary_bank_account: true,
        });
        batch.commit().then(() => {
          //  Check to see if another bank account is already set to "primary" and remove it if true
          const bankRef = db
            .collection("bank_accounts")
            .where("bank_account_id", "!=", this.company_bank_id);
          bankRef
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.update({
                  primary_bank_account: false,
                });
              });
            })
            .catch((error) => error);

          this.loading = false;
          showSnackbar("Company details updated successfully");
          this.$router.push("/");
        });
      }
    },
    ShowAdvancedFields() {
      this.advanced_fields = true;
      this.$refs.advanced_fields.scrollIntoView();
    },
    imageUploadClick() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.company_logo = null;
      this.imageData = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      this.company_logo = null;
      const storageRef = firebase
        .storage()
        .ref("company-img/" + `${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.company_logo = url;
            console.log(this.company_logo);
          });
        }
      );
    },
  },
};
</script>